// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height */
  background-color: #f0f0f0; /* Light background color */
}

.register-container {
  background-color: #fff; /* White background for the form */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.form-group {
  margin-bottom: 15px; /* Space between form fields */
}

.form-input {
  width: 100%; /* Full width inputs */
  padding: 10px;
  border: 1px solid #ccc; /* Light border */
  border-radius: 4px; /* Rounded corners */
}

.register-button {
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
  padding: 10px;
  border: none;
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Pointer on hover */
}

.register-button:hover {
  background-color: #45a049; /* Darker green on hover */
}

.error-message {
  color: red; /* Red color for error messages */
}

.success-message {
  color: green; /* Green color for success messages */
}
`, "",{"version":3,"sources":["webpack://./src/pages/RegisterPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa,EAAE,gBAAgB;EAC/B,yBAAyB,EAAE,2BAA2B;AACxD;;AAEA;EACE,sBAAsB,EAAE,kCAAkC;EAC1D,aAAa;EACb,kBAAkB;EAClB,uCAAuC,EAAE,kBAAkB;AAC7D;;AAEA;EACE,mBAAmB,EAAE,8BAA8B;AACrD;;AAEA;EACE,WAAW,EAAE,sBAAsB;EACnC,aAAa;EACb,sBAAsB,EAAE,iBAAiB;EACzC,kBAAkB,EAAE,oBAAoB;AAC1C;;AAEA;EACE,yBAAyB,EAAE,qBAAqB;EAChD,YAAY,EAAE,eAAe;EAC7B,aAAa;EACb,YAAY;EACZ,kBAAkB,EAAE,oBAAoB;EACxC,eAAe,EAAE,qBAAqB;AACxC;;AAEA;EACE,yBAAyB,EAAE,0BAA0B;AACvD;;AAEA;EACE,UAAU,EAAE,iCAAiC;AAC/C;;AAEA;EACE,YAAY,EAAE,qCAAqC;AACrD","sourcesContent":[".register-page {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh; /* Full height */\n  background-color: #f0f0f0; /* Light background color */\n}\n\n.register-container {\n  background-color: #fff; /* White background for the form */\n  padding: 20px;\n  border-radius: 5px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */\n}\n\n.form-group {\n  margin-bottom: 15px; /* Space between form fields */\n}\n\n.form-input {\n  width: 100%; /* Full width inputs */\n  padding: 10px;\n  border: 1px solid #ccc; /* Light border */\n  border-radius: 4px; /* Rounded corners */\n}\n\n.register-button {\n  background-color: #4CAF50; /* Green background */\n  color: white; /* White text */\n  padding: 10px;\n  border: none;\n  border-radius: 4px; /* Rounded corners */\n  cursor: pointer; /* Pointer on hover */\n}\n\n.register-button:hover {\n  background-color: #45a049; /* Darker green on hover */\n}\n\n.error-message {\n  color: red; /* Red color for error messages */\n}\n\n.success-message {\n  color: green; /* Green color for success messages */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
