import React, { useState, useEffect } from 'react';
import './customerinfo.css';

const fieldMapping = {
    name: 'name',
    street_name: 'street_name',
    postal_code: 'postal_code',
    city: 'city',
    NaceKode: 'NaceKode',
    Bransje: 'Bransje',
    AntallAnsatte: 'AntallAnsatte',
    note: 'note',
    orgform: 'orgform'
};

const UpdateCustomer = () => {
    const [registrationNumber] = useState(localStorage.getItem('selectedCustomer') || '');
    const [customerData, setCustomerData] = useState({
        name: '',
        street_name: '',
        postal_code: '',
        city: '',
        naceKode: '',
        bransje: '',
        antallAnsatte: '',
        note: '',
        orgform: ''
    });
    const [message, setMessage] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    // Fetch customer data
    useEffect(() => {
        const fetchCustomerData = async () => {
            if (!registrationNumber) return;

            setLoading(true);
            try {
                const response = await fetch(`https://app.ecofocus.no:3443/api/clients?registrationNumber=${registrationNumber}`);

                if (!response.ok) {
                    const errorText = await response.text();
                    throw new Error(errorText || 'Failed to fetch customer data');
                }

                const data = await response.json();
                if (data.length > 0) {
                    // Map fetched data to the customerData state
                    setCustomerData(Object.keys(fieldMapping).reduce((acc, key) => {
                        acc[fieldMapping[key]] = data[0][fieldMapping[key]] || '';
                        return acc;
                    }, {}));
                    setMessage('');
                } else {
                    setMessage('Customer not found');
                }
            } catch (error) {
                setError('Error fetching customer data');
            } finally {
                setLoading(false);
            }
        };

        fetchCustomerData();
    }, [registrationNumber]);

    const handleChange = (e) => {
        setCustomerData({
            ...customerData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await fetch('https://app.ecofocus.no:3443/api/clients', {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    registrationNumber,
                    // Map customerData back to database field names
                    ...Object.keys(customerData).reduce((acc, key) => {
                        acc[fieldMapping[key]] = customerData[key];
                        return acc;
                    }, {})
                })
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(errorText);
            }

            setMessage('Customer information updated successfully.');
            setError(null);
        } catch (error) {
            setError('Error updating customer data. Please try again.');
            setMessage('');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container">
            <h2>Oppdater kundedata</h2>
            <div className="title-underline"></div>
            {loading && <p>Loading...</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {message && <p>{message}</p>}
            <form onSubmit={handleSubmit}>
            <div className="customerFlex">
                {/* Group 1: Basic Information */}
                <fieldset>
                    <legend>Informasjon</legend>
                    <div className="form-group">
                        <label htmlFor="name">Navn:</label>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            value={customerData.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="street_name">Adresse:</label>
                        <input
                            type="text"
                            name="street_name"
                            id="street_name"
                            value={customerData.street_name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="postal_code">Postnr:</label>
                        <input
                            type="text"
                            name="postal_code"
                            id="postal_code"
                            value={customerData.postal_code}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="city">Poststed:</label>
                        <input
                            type="text"
                            name="city"
                            id="city"
                            value={customerData.city}
                            onChange={handleChange}
                        />
                    </div>
                </fieldset>

                {/* Group 2: Business Information */}
                <fieldset>
                    <legend>Organisasjon</legend>
                    <div className="form-group">
                        <label htmlFor="naceKode">Nace Kode:</label>
                        <input
                            type="text"
                            name="naceKode"
                            id="naceKode"
                            value={customerData.NaceKode}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="bransje">Bransje:</label>
                        <input
                            type="text"
                            name="bransje"
                            id="bransje"
                            value={customerData.Bransje}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="orgform">Organisasjonsform:</label>
                        <input
                            type="text"
                            name="orgform"
                            id="orgform"
                            value={customerData.orgform}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="antallAnsatte">Ansatte:</label>
                        <input
                            type="text"
                            name="antallAnsatte"
                            id="antallAnsatte"
                            value={customerData.AntallAnsatte}
                            onChange={handleChange}
                        />
                    </div>
                </fieldset>

                {/* Group 3: Notes */}
                <fieldset>
                    <legend>Notat</legend>
                    <div className="form-group">
                        <label htmlFor="note">Notat:</label>
                        <input
                            type="text"
                            name="note"
                            id="note"
                            value={customerData.note}
                            onChange={handleChange}
                        />
                    </div>
                </fieldset>
                </div>
                <button type="submit" className="save-button" disabled={loading}>Lagre endringer</button>
            </form>
        </div>
    );
};

export default UpdateCustomer;
