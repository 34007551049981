import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select'; // Import react-select
import { FaHome, FaUpload, FaInfoCircle, FaDatabase, FaCog, FaFileAlt, FaChevronDown, FaChevronUp, FaBars, FaSignOutAlt } from 'react-icons/fa';
const Sidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State for isSidebarOpen  sidebar
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [availableYears, setAvailableYears] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(localStorage.getItem('selectedCustomer') || '');
  const [selectedYear, setSelectedYear] = useState(localStorage.getItem('selectedYear') || '');
  const [loadingYears, setLoadingYears] = useState(false); // Loading state for years
  const userId = localStorage.getItem('userId');
  const navigate = useNavigate();
  const userRole = localStorage.getItem('userRole'); // Get the user role from local storage

  const [isTalldataOpen, setIsTalldataOpen] = useState(false); // State for Talldata section
  const [isGrunnlagsdataOpen, setIsGrunnlagsdataOpen] = useState(false); // State for Grunnlagsdata submenu

  const toggleSidebar = () => {
    setIsSidebarOpen(prev => !prev); // Toggle open/closed state
  };

  // Handle logout
  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('userId');
    localStorage.removeItem('selectedCustomer');
    localStorage.removeItem('selectedYear');
    localStorage.removeItem('userRole');
    setSelectedCustomer(''); // Clear customer state on logout
    setSelectedYear(''); // Clear year state on logout
    setCustomers([]); // Clear customer data
    setAvailableYears([]); // Clear year data
    navigate('/login');
  };

  const toggleTalldata = () => {
    setIsTalldataOpen((prev) => !prev); // Toggle the open/closed state
  };

  const toggleGrunnlagsdata = () => {
    setIsGrunnlagsdataOpen((prev) => !prev); // Toggle Grunnlagsdata submenu
  };

  return (
    <div className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
      <div className="sidebar-header">
      {isSidebarOpen && (
        <Link to="/" className="logo-link">
          <img className="logo" src="https://app.ecofocus.no/logo.png" alt="EcoFocus" />
        </Link>
      )}
        <button onClick={toggleSidebar} className="toggle-button">
          <FaBars className="icon-bars" />
        </button>
      </div>
      <nav className="nav-links">
        <Link to="/" className="nav-item">
          <FaHome className="nav-icon" /> {isSidebarOpen && 'Dashboard'}
        </Link>
        <Link to="/upload-saft" className="nav-item">
          <FaUpload className="nav-icon" /> {isSidebarOpen && 'Last Opp'}
        </Link>
        <Link to="/UploadTripletex" className="nav-item">
          <FaUpload className="nav-icon" /> {isSidebarOpen && 'Importer'}
        </Link>

        <Link to="/info" className="nav-item">
          <FaInfoCircle className="nav-icon" /> {isSidebarOpen && 'Informasjon'}
        </Link>

        {userRole === 'admin' && (
          <Link to="/administrasjon" className="nav-item">
            <FaCog className="nav-icon" /> {isSidebarOpen && 'Administrasjon'}
          </Link>
        )}
        {userRole === 'admin' && (
          <Link to="/newclient" className="nav-item">
            <FaFileAlt className="nav-icon" /> {isSidebarOpen && 'Opprett Kunde'}
          </Link>
              )}
                  {userRole === 'admin' && (
          <Link to="/rapportdata" className="nav-item">
            <FaFileAlt className="nav-icon" /> {isSidebarOpen && 'Rapportdata'}
          </Link>
        )}
        <Link to="/rapporter" className="nav-item">
          <FaFileAlt className="nav-icon" /> {isSidebarOpen && 'Rapporter'}
        </Link>

        {/* Grunnlagsdata Menu */}
        {isSidebarOpen && (
          <div className="nav-item" onClick={toggleGrunnlagsdata}>
            <FaDatabase className="nav-icon" /> Grunnlagsdata {isGrunnlagsdataOpen ? <FaChevronUp /> : <FaChevronDown />}
          </div>
        )}
        {isGrunnlagsdataOpen && isSidebarOpen && (
          <div className="sub-nav-links">
            <Link to="/energy">Strøm og Energi</Link>
            <Link to="/cars">Biler og annen forbrenning</Link>
            <Link to="/escaped-emissions">Rømningsrelaterte utslipp</Link>
            <Link to="/ansatte">Ansatte</Link>
            <Link to="/integrasjoner">Integrasjoner</Link>
          </div>
        )}

        {/* Talldata Menu */}
        {isSidebarOpen && (
          <div className="nav-item" onClick={toggleTalldata}>
            <FaFileAlt className="nav-icon" /> Talldata {isTalldataOpen ? <FaChevronUp /> : <FaChevronDown />}
          </div>
        )}
        {isTalldataOpen && isSidebarOpen && (
          <div className="sub-nav-links">
            <Link to="/suppliers">Leverandører</Link>
            <Link to="/kontoplan">Kontoplan</Link>
            <Link to="/hovedbok">Hovedbok</Link>
          </div>
        )}
      </nav>
      <button onClick={handleLogout} className="logout-button">
        <FaSignOutAlt className="nav-icon" /> {isSidebarOpen && 'Logg av'}
      </button>
    </div>

  );
};

export default Sidebar;
