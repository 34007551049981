import React, { useEffect, useState } from 'react';
import { Pie, Line, Bar } from 'react-chartjs-2';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { Chart, ArcElement, LineElement, BarElement, CategoryScale, LinearScale, PointElement } from 'chart.js';
import './Dashboard.css';

// Register Pie, Line, and Bar chart components
Chart.register(ArcElement, LineElement, BarElement, CategoryScale, LinearScale, PointElement);

const Dashboard = () => {
    const [summaryData, setSummaryData] = useState([]);
    const [supplierData, setSupplierData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const customerOrgNr = localStorage.getItem('selectedCustomer') || '';
    const [year, setYear] = useState(localStorage.getItem('selectedYear') || '');


    const fetchSummaryData = async () => {
        setLoading(true);
        try {
            const response = await fetch(`https://app.ecofocus.no:3443/api/dashboard/summary?year=${year}&customer=${customerOrgNr}`);
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Error ${response.status}: ${errorText}`);
            }
            const data = await response.json();

            // Assume the backend provides a separate supplierData object
            const supplierData = data.suppliersdata || [];
            console.log(supplierData);
            setSummaryData(data.summary || []);  // set main summary data
            setSupplierData(supplierData);      // set supplier data separately
        } catch (error) {
            console.error('Error fetching summary data:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };




    const getPieChartData = () => {
        const totals = { 'Scope 1': 0, 'Scope 2': 0, 'Scope 3': 0 };

        summaryData.forEach(entry => {
            const scope = entry.Scope;
            const totalAmount = parseFloat(entry.totalDebitAmountWithFactor);
            if (!isNaN(totalAmount)) {
                if (scope === '1') totals['Scope 1'] += totalAmount;
                else if (scope === '2') totals['Scope 2'] += totalAmount;
                else if (scope === '3') totals['Scope 3'] += totalAmount;
            }
        });

        return {
            totals,
            labels: ['Scope 1', 'Scope 2', 'Scope 3'],
            datasets: [
                {
                    label: 'Total Amount With Factor',
                    data: [totals['Scope 1'], totals['Scope 2'], totals['Scope 3']],
                    backgroundColor: ['#5555FF', '#FF55B8', '#FF8787'],
                    hoverBackgroundColor: ['#5555AA', '#FF5588', '#FF6666'],
                    borderWidth: 1,
                },
            ],
        };
    };

    // Create a mapping object for categories
    const categoryMapping = {
      "Staionary combustion": {
          scope: "1",
          displayName: "Stasjonær forbrenning"
      },
      "Escaped emissions": {
          scope: "1",
          displayName: "Rømningsrelaterte utslipp "
      },
        "Mobile combustion": {
            scope: "1",
            displayName: "Mobil forbrenning"
        }, "Purchased energy": {
            scope: "2",
            displayName: "Utslipp fra innkjøpt energi"
        }, "s3kat1": {
            scope: "3",
            displayName: "Innkjøp av varer og tjenester"
        }, "s3kat2": {
            scope: "3",
            displayName: "Anleggsmidler"
        }, "s3kat3": {
            scope: "3",
            displayName: "Utslipp relatert til energiproduksjon"
        }, "s3kat4": {
            scope: "3",
            displayName: "Kjøpt transport og distribusjon"
        },
        "s3kat5": {
            scope: "3",
            displayName: "Avfallshåndtering"
        },
        "s3kat6": {
            scope: "3",
            displayName: "Forretningsreiser"
        },
        "s3kat7": {
            scope: "3",
            displayName: "Ansattes reiser til og fra jobb"
        },
        "s3kat8": {
            scope: "3",
            displayName: "Leide lokaler/utstyr"
        },
        "s3kat9": {
            scope: "3",
            displayName: "Nedstrøms transport og distribusjon"
        },
        "s3kat10": {
            scope: "3",
            displayName: "Prosessering av solgte produkter"
        },
        "s3kat11": {
            scope: "3",
            displayName: "Bruk av solgte produkter"
        },
        "s3kat12": {
            scope: "3",
            displayName: "Sluttbehandling av solgte produkter"
        },
        "s3kat13": {
            scope: "3",
            displayName: "Utleide lokaler/utstyrr"
        },
        "s3kat14": {
            scope: "3",
            displayName: "Franchiser"
        },
        "s3kat15": {
            scope: "3",
            displayName: "Investeringer"
        },
    };
    const getCategoryData = () => {
        const categoryTotals = {};

        summaryData.forEach(entry => {
            const category = entry.Category;
            const totalAmount = parseFloat(entry.totalDebitAmountWithFactor);

            if (!isNaN(totalAmount) && categoryMapping[category]) {
                const mappedCategory = categoryMapping[category];
                const displayName = mappedCategory.displayName;

                // Initialize category total if it doesn't exist
                if (!categoryTotals[displayName]) {
                    categoryTotals[displayName] = {
                        totalAmount: 0,
                        scope: mappedCategory.scope
                    };
                }

                // Accumulate the total amount for the mapped category
                categoryTotals[displayName].totalAmount += totalAmount;
            }
        });

        // Transform the totals into the desired format
   const transformedTotals = {};
   for (const [displayName, { totalAmount, scope }] of Object.entries(categoryTotals)) {
       transformedTotals[displayName] = totalAmount;
   }

   return transformedTotals;
};

    const getClassificationData = () => {
        const classificationTotals = {};
        summaryData.forEach(entry => {
            const classification = entry.Classification;
            const totalAmount = parseFloat(entry.totalDebitAmountWithFactor);
            if (!isNaN(totalAmount)) {
                classificationTotals[classification] = (classificationTotals[classification] || 0) + totalAmount;
            }
        });
        return classificationTotals;
    };
    // New function to calculate total DebitAmountWithFactor per supplier
    const getSupplierSummaryData = () => {
        const supplierTotals = {};

        // Calculate total for each supplier by CustomerID
        summaryData.forEach(entry => {
            const customerID = entry.CustomerID;
            const totalAmount = parseFloat(entry.totalDebitAmountWithFactor);

            if (customerID && customerID !== 0 && !isNaN(customerID) && !isNaN(totalAmount)) {
                supplierTotals[customerID] = (supplierTotals[customerID] || 0) + totalAmount;
            }
        });

        // Create a map for supplier details from supplierData
        const supplierDetailsMap = supplierData.reduce((map, supplier) => {
            map[supplier.CustomerSupplierID] = {
                name: supplier.Name,
                registrationNumber: supplier.RegistrationNumber,
                id: supplier.id,
            };
            return map;
        }, {});

        // Convert supplierTotals to an array, add supplier details, sort, and return the top 10
        return Object.entries(supplierTotals)
            .map(([customerID, total]) => ({
                customerID,
                name: supplierDetailsMap[customerID]?.name || 'Unknown',
                registrationNumber: supplierDetailsMap[customerID]?.registrationNumber || 'Unknown',
                  id: supplierDetailsMap[customerID]?.id || 'Unknown',
                total: total.toFixed(2),
            }))
            .sort((a, b) => b.total - a.total)
            .slice(0, 10); // Return only the first 10 entries
    };



    const getLineChartData = () => {
        const months = Array.from({ length: 12 }, (_, i) => i + 1);
        const scope1Data = Array(12).fill(0);
        const scope2Data = Array(12).fill(0);
        const scope3Data = Array(12).fill(0);

        summaryData.forEach(entry => {
            const month = entry.month - 1;
            const totalAmount = parseFloat(entry.totalDebitAmountWithFactor);
            if (!isNaN(totalAmount)) {
                if (entry.Scope === '1') scope1Data[month] += totalAmount;
                else if (entry.Scope === '2') scope2Data[month] += totalAmount;
                else if (entry.Scope === '3') scope3Data[month] += totalAmount;
            }
        });

        return {
            labels: [
                'Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni',
                'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'
            ],
            datasets: [
                {
                    label: 'Scope 1',
                    data: scope1Data,
                    borderColor: '#5555FF',
                    backgroundColor: 'rgba(85, 85, 255, 0.2)',
                    fill: true,
                    tension: 0.4,
                },
                {
                    label: 'Scope 2',
                    data: scope2Data,
                    borderColor: '#FF55B8',
                    backgroundColor: 'rgba(255, 85, 184, 0.2)',
                    fill: true,
                    tension: 0.4,
                },
                {
                    label: 'Scope 3',
                    data: scope3Data,
                    borderColor: '#FF8787',
                    backgroundColor: 'rgba(255, 135, 135, 0.2)',
                    fill: true,
                    tension: 0.4,
                },
            ],
        };
    };


    useEffect(() => {
        fetchSummaryData();
    }, [customerOrgNr, year]);

    const pieChartData = getPieChartData();
    const categoryTotals = getCategoryData();
    const classificationTotals = getClassificationData();
    const lineChartData = getLineChartData();

    // Chart data for categories
    const categoryChartData = {
        labels: Object.keys(categoryTotals),
        datasets: [
            {
                label: 'Totalt utslipp per kategori',
                data: Object.values(categoryTotals),
                backgroundColor: ['#5555FF', '#FF55B8', '#FF8787', '#FFA500', '#008000'],
                borderColor: '#fff',
                borderWidth: 1,
            },
        ],
    };

    // Chart data for classifications
    const classificationChartData = {
        labels: Object.keys(classificationTotals),
        datasets: [
            {
                label: 'Total Debit Amount With Factor by Classification',
                data: Object.values(classificationTotals),
                backgroundColor: ['#FF4500', '#FF6347', '#FF8C00', '#FFD700', '#32CD32'],
                borderColor: '#fff',
                borderWidth: 1,
            },
        ],
    };
    if (!customerOrgNr || !year) {
       return (
           <main className="dashboard">
               <h2>Dashboard</h2>
               <p className="error">Velg kunde og år for å se dashbord.</p>
           </main>
       );
   }
const supplierSummary = getSupplierSummaryData();
    return (
        <main className="dashboard">
            <h2>Dashboard</h2>
            {loading ? (
                <p>Laster data, vennligst vent...</p>
            ) : error ? (
                <p className="error">Error: {error}</p>
            ) : (
              <div className="charts-container">
                <div className="charts-container-hor">
                    {/* Pie Chart */}
                    <div className="card pie-card">
                        <div className="about">
                            <h3>Utslipp per Scope</h3>
                            <p className="lead">Totalt utslipp (Co2e) i valgt år fordelt per Scope</p>
                            <div className="legends">
                                <div className="info">
                                    <span className="legend legend--this"></span>
                                    <p>Scope 1: {pieChartData.totals['Scope 1'].toFixed(2)}</p>
                                </div>
                                <div className="info">
                                    <span className="legend legend--prev"></span>
                                    <p>Scope 2: {pieChartData.totals['Scope 2'].toFixed(2)}</p>
                                </div>
                                <div className="info">
                                    <span className="legend legend--third"></span>
                                    <p>Scope 3: {pieChartData.totals['Scope 3'].toFixed(2)}</p>
                                </div>
                            </div>
                        </div>
                        <div className="chart-container">
                            <Pie data={pieChartData} options={{ responsive: true, maintainAspectRatio: false }} />
                        </div>
                    </div>

                    {/* Category Totals Chart */}
                    <div className="card category-card">
                        <div className="about">
                            <h3>Utslipp per kategori</h3>
                            <p className="lead">Totalt Co2e per kategori</p>
                        </div>
                        <div className="chart-container">
                            <Bar data={categoryChartData} options={{ responsive: true, maintainAspectRatio: false }} />
                        </div>
                    </div>
                </div>
                {/* Supplier Summary Table */}
                <div className="card supplier-summary-card wide-card">
                    <h3>Leverandører med størst utslipp</h3>
                    <table>
                        <thead>
                            <tr>
                                <th>Navn</th>
                                <th>Lev.nr.</th>

                                <th>Co2e</th>
                            </tr>
                        </thead>
                        <tbody>
                            {supplierSummary.map((supplier) => (
                                <tr key={supplier.customerID}>
                                    <td>
                                        <Link to={`/supplier/${supplier.id}`}>{supplier.name}</Link>
                                    </td>
                                    <td>
                                        <Link to={`/supplier/${supplier.id}`}>{supplier.customerID}</Link>
                                    </td>

                                    <td>{supplier.total}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                  </div>

            )}
              <div className="charts-container">
            {/* Classification Totals Chart */}
            <div className="card classification-card">
                <div className="about">
                    <h3>Utslipp per klassifisering</h3>
                    <p className="lead">Totalt Co2e per klassifisering</p>
                </div>
                <div className="chart-container">
                    <Bar data={classificationChartData} options={{ responsive: true, maintainAspectRatio: false }} />
                </div>
            </div>
            {/* Monthly Breakdown Chart */}
            <div className="card wide-chart">
                <div className="about">
                    <h3>Utslipp per måned</h3>
                    <p className="lead">Co2e fordelt per Scope per måned</p>
                </div>
                <div className="chart-container">
                    <Line data={lineChartData} options={{ responsive: true, maintainAspectRatio: false }} />
                </div>
            </div>
            </div>
        </main>
    );
};

export default Dashboard;
