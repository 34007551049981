import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import './Administration.css';

const AdministrationPage = () => {
    const userRole = localStorage.getItem('userRole'); // Get the user role from local storage
    return (
        <div className="administration-container">
            <h1>Administrasjon</h1>
            <div className="admin-boxes">
                <div className="admin-box">
                    <h2>Klienter</h2>
                    <p>This section deals with client management, where you can view and manage client data.</p>
                    <Link to="/klientadministration">
                        <button className="open-btn">Åpne Klientadministration</button>
                    </Link>
                </div>
                <div className="admin-box">
                    <h2>Brukere</h2>
                    <p>This section is for user management, allowing you to manage user accounts and permissions.</p>
                    <Link to="/brukeradministration">
                        <button className="open-btn">Åpne Brukeradministration</button>
                    </Link>
                </div>


                {(userRole === 'admin' || userRole === 'utvikler') && (
                    <div className="admin-box">
                        <h2>Annen Adminfunksjon</h2>
                        <p>Annen info som kan endres av admin og utvikler.</p>
                        <Link to="/annenadminfunksjon">
                            <button className="open-btn">Åpne Annen Adminfunksjon</button>
                        </Link>
                    </div>
                  )}
                <div className="admin-box">
                    <h2>Faktura</h2>
                    <p>This section manages invoice data, including viewing and editing invoices.</p>
                    <Link to="/fakturaadministration">
                        <button className="open-btn">Åpne Fakturaadministration</button>
                    </Link>
                </div>
                                {(userRole === 'admin' || userRole === 'utvikler') && (
                <div className="admin-box">
                    <h2>Utslippsfaktorer</h2>
                    <p>This section provides tools to manage emissions factors and related data.</p>
                    <Link to="/utslippsfaktorer">
                        <button className="open-btn">Åpne Utslippsfaktorer</button>
                    </Link>
                </div>
                )}
            </div>
        </div>
    );
};

export default AdministrationPage;
