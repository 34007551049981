// AppContext.js
import React, { createContext, useContext, useState } from 'react';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [selectedYear, setSelectedYear] = useState('');

    return (
        <AppContext.Provider value={{ selectedCustomer, setSelectedCustomer, selectedYear, setSelectedYear }}>
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => useContext(AppContext);
