import React, { useEffect, useState } from 'react';
import './Hovedbok.css';

const Hovedbok = () => {
    const [hovedbokData, setHovedbokData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [customerOrgNr, setCustomerOrgNr] = useState(localStorage.getItem('selectedCustomer') || '');
    const [year, setYear] = useState(localStorage.getItem('selectedYear') || '');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [filters, setFilters] = useState({
        transactionId: '',
        accountId: '',
        customerName: '',
        customerRegistrationnumber: '',
        customerId: '',
        customerFactor: '',
        transactionDate: '',
        debitAmount: '',
        category: '',
        classification: '',
        standardKonto: '',
    });

    const rowsPerPage = 12;
    const [totalCount, setTotalCount] = useState(0);

    const fetchHovedbok = async () => {
        if (!year || !customerOrgNr) return;

        setLoading(true);
        const controller = new AbortController();
        const signal = controller.signal;

        let fetchUrl = `https://app.ecofocus.no:3443/api/hovedbok?customer=${customerOrgNr}&` +
            `transactionId=${filters.transactionId}&accountId=${filters.accountId}&` +
            `customerName=${filters.customerName}&customerRegistrationnumber=${filters.customerRegistrationnumber}&` +
            `customerId=${filters.customerId}&customerFactor=${filters.customerFactor}&` +
            `transactionDate=${filters.transactionDate}&debitAmount=${filters.debitAmount}&` +
            `category=${filters.category}&classification=${filters.classification}&` +
            `standardKonto=${filters.standardKonto}&limit=${rowsPerPage}&page=${currentPage}`;

        if (year) {
            fetchUrl += `&year=${year}`;
        }

        console.log('Fetching Hovedbok data from:', fetchUrl);

        try {
            const response = await fetch(fetchUrl, { signal });
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Error ${response.status}: ${errorText}`);
            }

            const result = await response.json();
            setHovedbokData(result.customerlist);
            setTotalCount(result.totalCount);
        } catch (error) {
            if (error.name !== 'AbortError') {
                console.error('Error fetching Hovedbok data:', error);
                setError(error.message);
            }
        } finally {
            setLoading(false);
        }

        return () => {
            controller.abort();
        };
    };

    useEffect(() => {
        fetchHovedbok();
    }, [customerOrgNr, currentPage, filters]);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prev) => ({ ...prev, [name]: value }));
        setCurrentPage(1);
    };

    const totalPages = Math.ceil(totalCount / rowsPerPage);

    return (
        <div className="table-container">
            <h1>Hovedbok</h1>

            {loading ? (
                <p>Loading Hovedbok data, please wait...</p>
            ) : error ? (
                <p className="no-data">Error: {error}</p>
            ) : (
                <>
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <input
                                        type="text"
                                        name="transactionId"
                                        placeholder="Søk"
                                        value={filters.transactionId}
                                        onChange={handleFilterChange}
                                        style={{ width: '100%', border: '1px solid #ccc', outline: 'none', padding: '8px', boxSizing: 'border-box' }}
                                    />
                                </th>
                                <th>
                                    <input
                                        type="text"
                                        name="accountId"
                                        placeholder="Søk"
                                        value={filters.accountId}
                                        onChange={handleFilterChange}
                                        style={{ width: '100%', border: '1px solid #ccc', outline: 'none', padding: '8px', boxSizing: 'border-box' }}
                                    />
                                </th>
                                <th>
                                    <input
                                        type="text"
                                        name="customerName"
                                        placeholder="Søk"
                                        value={filters.customerName}
                                        onChange={handleFilterChange}
                                        style={{ width: '100%', border: '1px solid #ccc', outline: 'none', padding: '8px', boxSizing: 'border-box' }}
                                    />
                                </th>
                                <th>
                                    <input
                                        type="text"
                                        name="customerRegistrationnumber"
                                        placeholder="Søk"
                                        value={filters.customerRegistrationnumber}
                                        onChange={handleFilterChange}
                                        style={{ width: '100%', border: '1px solid #ccc', outline: 'none', padding: '8px', boxSizing: 'border-box' }}
                                    />
                                </th>
                                <th>
                                    <input
                                        type="text"
                                        name="customerId"
                                        placeholder="Søk"
                                        value={filters.customerId}
                                        onChange={handleFilterChange}
                                        style={{ width: '100%', border: '1px solid #ccc', outline: 'none', padding: '8px', boxSizing: 'border-box' }}
                                    />
                                </th>
                                <th>
                                    <input
                                        type="text"
                                        name="customerFactor"
                                        placeholder="Søk"
                                        value={filters.customerFactor}
                                        onChange={handleFilterChange}
                                        style={{ width: '100%', border: '1px solid #ccc', outline: 'none', padding: '8px', boxSizing: 'border-box' }}
                                    />
                                </th>
                                <th>
                                    <input
                                        type="text"
                                        name="transactionDate"
                                        placeholder="Søk"
                                        value={filters.transactionDate}
                                        onChange={handleFilterChange}
                                        style={{ width: '100%', border: '1px solid #ccc', outline: 'none', padding: '8px', boxSizing: 'border-box' }}
                                    />
                                </th>
                                <th>
                                    <input
                                        type="text"
                                        name="debitAmount"
                                        placeholder="Søk"
                                        value={filters.debitAmount}
                                        onChange={handleFilterChange}
                                        style={{ width: '100%', border: '1px solid #ccc', outline: 'none', padding: '8px', boxSizing: 'border-box' }}
                                    />
                                </th>
                                <th>
                                    <input
                                        type="text"
                                        name="category"
                                        placeholder="Søk"
                                        value={filters.category}
                                        onChange={handleFilterChange}
                                        style={{ width: '100%', border: '1px solid #ccc', outline: 'none', padding: '8px', boxSizing: 'border-box' }}
                                    />
                                </th>
                                <th>
                                    <input
                                        type="text"
                                        name="classification"
                                        placeholder="Søk"
                                        value={filters.classification}
                                        onChange={handleFilterChange}
                                        style={{ width: '100%', border: '1px solid #ccc', outline: 'none', padding: '8px', boxSizing: 'border-box' }}
                                    />
                                </th>
                                <th>
                                    <input
                                        type="text"
                                        name="standardKonto"
                                        placeholder="Søk"
                                        value={filters.standardKonto}
                                        onChange={handleFilterChange}
                                        style={{ width: '100%', border: '1px solid #ccc', outline: 'none', padding: '8px', boxSizing: 'border-box' }}
                                    />
                                </th>
                            </tr>
                            <tr>
                                <th>ID</th>
                                <th>KontoNr</th>
                                <th>Kunde/Lev</th>
                                <th>Customer Registration Number</th>
                                <th>Customer ID</th>
                                <th>Customer Factor</th>
                                <th>Dato</th>
                                <th>Beløp</th>
                                <th>Kategori</th>
                                <th>Klasse</th>
                                <th>Standard Konto</th>
                                <th>Faktor</th>
                                <th>Beregnet utslipp</th>
                                <th>Scope</th>
                                <th>Kalyle</th>
                            </tr>
                        </thead>
                        <tbody>
                            {hovedbokData.length > 0 ? (
                                hovedbokData.map((entry) => (
                                    <tr key={entry.TransactionID}>
                                        <td>{entry.TransactionID}</td>
                                        <td>{entry.AccountID}</td>
                                        <td>{entry.CustomerName || "N/A"}</td>
                                        <td>{entry.customerRegistrationnumber}</td>
                                        <td>{entry.CustomerID}</td>
                                        <td>{entry.CustomerFactor}</td>
                                        <td>{new Date(entry.TransactionDate).toLocaleDateString()}</td>
                                        <td>{entry.DebitAmount}</td>
                                        <td>{entry.Category}</td>
                                        <td>{entry.Classification}</td>
                                        <td>{entry.StandardKonto}</td>
                                        <td>{entry.Factor}</td>
                                        <td>{entry.DebitAmountWithFactor}</td>
                                        <td>{entry.Scope}</td>
                                        <td>{entry.Calculation}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="15" className="no-data">No data found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                    <div className="pagination">
                        <button
                            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                            disabled={currentPage === 1}
                        >
                            Prev
                        </button>
                        <span>
                            Page {currentPage} of {totalPages}
                        </span>
                        <button
                            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default Hovedbok;
