import React, { useEffect, useState } from 'react';
import './Kontoplan.css'; // Import the CSS file

const Kontoplan = () => {
    const [kontoplan, setKontoplan] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedYear, setSelectedYear] = useState(localStorage.getItem('selectedYear') || '');
    const [selectedCustomer, setSelectedCustomer] = useState(localStorage.getItem('selectedCustomer') || '');
    const [loading, setLoading] = useState(false); // Loading state
    const rowsPerPage = 10; // Number of rows per page

    // State to track changes
    const [editedRows, setEditedRows] = useState({});

    // Function to fetch Kontoplan data
    const fetchKontoplan = async () => {
        if (!selectedYear || !selectedCustomer) return; // Ensure both are selected

        setLoading(true); // Set loading to true
        const controller = new AbortController(); // Create an AbortController
        const signal = controller.signal; // Get the signal

        try {
            const response = await fetch(`https://app.ecofocus.no:3443/api/kontoplan?year=${selectedYear}&customer=${selectedCustomer}`, { signal });
            if (!response.ok) throw new Error('Network response was not ok');

            const data = await response.json();

            // Check if data.kontoplan is an array
            if (Array.isArray(data.kontoplan)) {
                const sortedKontoplan = data.kontoplan.sort((a, b) => {
                    return parseInt(a.AccountID) - parseInt(b.AccountID); // Sort numerically
                });
                setKontoplan(sortedKontoplan); // Set the sorted data to state
            } else {
                console.error('Invalid data structure:', data);
                setKontoplan([]); // Set empty array if data is not in expected format
            }
        } catch (error) {
            if (error.name !== 'AbortError') {
                console.error('Error fetching Kontoplan data:', error);
            }
        } finally {
            setLoading(false); // Set loading to false after fetching
        }

        return () => {
            controller.abort(); // Cleanup: abort previous fetch
        };
    };

    useEffect(() => {
        fetchKontoplan(); // Call the fetch function in useEffect
    }, [selectedYear, selectedCustomer]); // Use state variables here

    // Function to handle updates
    const handleUpdate = async (accountID) => {
        const updatedFactor = editedRows[accountID]?.OverstyrtFactor;
        const updatedScope = editedRows[accountID]?.OverstyrtScope;

        if (!updatedFactor && !updatedScope) return; // If no updates are present, return

        try {
            const response = await fetch(`https://app.ecofocus.no:3443/api/kontoplan/${accountID}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    OverstyrtFactor: updatedFactor,
                    OverstyrtScope: updatedScope,
                    year: selectedYear,
                    customer: selectedCustomer,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log(data.message); // Log success message
            fetchKontoplan(); // Refetch data after update
        } catch (error) {
            console.error('Error updating account:', error);
        }
    };

    // Handle input changes
    const handleInputChange = (accountID, field, value) => {
        setEditedRows((prev) => ({
            ...prev,
            [accountID]: {
                ...prev[accountID],
                [field]: value,
            },
        }));
    };

    // Pagination Logic
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = kontoplan.slice(indexOfFirstRow, indexOfLastRow);
    const totalPages = Math.ceil(kontoplan.length / rowsPerPage);

    // Change page function
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className="table-container">
            <h1>Kontoplan</h1>
            {loading ? (
                <p>Loading data...</p> // Show loading message
            ) : kontoplan.length > 0 ? (
                <>
                    <table>
                        <thead>
                            <tr>
                                <th>KontoNr.</th>
                                <th>KontoNavn</th>
                                <th>Std. Konto</th>
                                <th>Std. Navn</th>
                                <th>IB</th>
                                <th>UB</th>
                                <th>Standard Faktor</th>
                                <th>Standard Scope</th>
                                <th>Overstyrt Faktor</th>
                                <th>Overstyrt Scope</th>
                                <th>Handling</th> {/* Add actions column */}
                            </tr>
                        </thead>
                        <tbody>
                            {currentRows.map((account) => (
                                <tr key={account.AccountID}>
                                    <td>{account.AccountID}</td>
                                    <td>{account.AccountName}</td>
                                    <td>{account.StandardKonto}</td>
                                    <td>{account.stdkontonavn}</td>
                                    <td>{account.InnSaldo}</td>
                                    <td>{account.UtSaldo}</td>
                                    <td>{account.StandardFactor}</td>
                                    <td>{account.StandardScope}</td>
                                    <td>
                                        <input
                                            type="text"
                                            defaultValue={account.OverstyrtFactor}
                                            onChange={(e) => handleInputChange(account.AccountID, 'OverstyrtFactor', e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            defaultValue={account.OverstyrtScope}
                                            onChange={(e) => handleInputChange(account.AccountID, 'OverstyrtScope', e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <button onClick={() => handleUpdate(account.AccountID)}>Lagre</button> {/* Save button */}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="pagination">
                        <button
                            className="previous"
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1} // Disable if on the first page
                        >
                            Previous
                        </button>
                        {/* Center the page numbers */}
                        {Array.from({ length: totalPages }, (_, index) => (
                            <button
                                key={index + 1}
                                onClick={() => handlePageChange(index + 1)}
                                className={currentPage === index + 1 ? 'active' : ''}
                            >
                                {index + 1}
                            </button>
                        ))}
                        <button
                            className="next"
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages} // Disable if on the last page
                        >
                            Next
                        </button>
                    </div>

                </>
            ) : (
                <p className="no-data">No data available.</p>
            )}
        </div>
    );
};

export default Kontoplan;
