import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation

const NewClient = () => {
    const userRole = localStorage.getItem('userRole'); // Get the user role from local storage
    const userId = localStorage.getItem('userId');


    return (
        <div className="administration-container">
            <h1>Opprett kunde</h1>
            <p> Fyll inn nødvendige felter for å opprette kunde.</p>
            <p> Trenger også å inkludere hvilket år som gjelder for å være sikker på at vi får valgt riktig i dropdown.</p>

        </div>
    );
};

export default NewClient;
