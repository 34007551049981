import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Select from 'react-select'; // Import react-select
import './Header.css';

const Header = () => {
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [availableYears, setAvailableYears] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(localStorage.getItem('selectedCustomer') || '');
  const [selectedYear, setSelectedYear] = useState(localStorage.getItem('selectedYear') || '');
  const [loadingYears, setLoadingYears] = useState(false); // Loading state for years
  const profileButtonRef = useRef(null);
  const navigate = useNavigate();
  const userId = localStorage.getItem('userId');

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await fetch(`https://app.ecofocus.no:3443/api/customers/${userId}`);
        const data = await response.json();

        if (Array.isArray(data) && data.length > 0) {
          setCustomers(data);
        } else {
          setCustomers([]); // If no customers, reset the state
          setSelectedCustomer(''); // Clear selected customer
          localStorage.removeItem('selectedCustomer'); // Remove from localStorage
          setAvailableYears([]); // Clear available years
          localStorage.removeItem('selectedYear'); // Remove year from localStorage
        }
      } catch (error) {
        console.error('Error fetching customers:', error);
        setCustomers([]); // Reset customers in case of an error
        setAvailableYears([]); // Reset years if error
      }
    };

    if (userId) {
      fetchCustomers();
    }
  }, [userId]);

  // Cache for available years
  const availableYearsCache = useRef({});

  // Fetch available years when selectedCustomer changes
  useEffect(() => {
    // Fetch available years when selectedCustomer changes
    const fetchAvailableYears = debounce(async () => {
        if (selectedCustomer) {
            if (availableYearsCache.current[selectedCustomer]) {
                setAvailableYears(availableYearsCache.current[selectedCustomer]);
                return; // Use cached years if available
            }

            setLoadingYears(true); // Set loading state to true
            try {
                const response = await fetch(`https://app.ecofocus.no:3443/api/customers/available-years/${selectedCustomer}`);
                if (response.ok) {
                    const data = await response.json();
                    const sortedYears = data.sort((a, b) => b.AccountYear - a.AccountYear);
                    availableYearsCache.current[selectedCustomer] = sortedYears; // Cache the fetched years
                    setAvailableYears(sortedYears);
                } else {
                    console.error('Error fetching available years:', response.statusText);
                    setAvailableYears([]);
                }
            } catch (error) {
                console.error('Error fetching available years:', error);
                setAvailableYears([]);
            } finally {
                setLoadingYears(false); // Set loading state to false after fetching
            }
        }
    }, 300); // 300 ms delay for debouncing

    // Call the function to fetch available years if a customer is selected
    if (selectedCustomer) {
        fetchAvailableYears();
    }
}, [selectedCustomer]);


  // Handle customer selection
  const handleCustomerSelect = (selectedOption) => {
    setSelectedCustomer(selectedOption.value);
    localStorage.setItem('selectedCustomer', selectedOption.value);
    setSelectedYear(''); // Clear selected year when customer changes
    localStorage.removeItem('selectedYear'); // Remove previous selected year from storage
  };

  // Handle year selection
  const handleYearSelect = (selectedOption) => {
    setSelectedYear(selectedOption.value);
    localStorage.setItem('selectedYear', selectedOption.value); // Persist to localStorage
    window.location.reload(); // Refresh the page to update Kontoplan
  };

  // Handle logout
  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('userId');
    localStorage.removeItem('selectedCustomer');
    localStorage.removeItem('selectedYear');
    setSelectedCustomer(''); // Clear customer state on logout
    setSelectedYear(''); // Clear year state on logout
    setCustomers([]); // Clear customer data
    setAvailableYears([]); // Clear year data
    navigate('/login');
  };

  // Convert customers and availableYears to format suitable for react-select
  const customerOptions = customers.map(customer => ({
    value: customer.RegistrationNumber,
    label: customer.CustomerName
  }));

  const yearOptions = availableYears.map(year => ({
    value: year.AccountYear,
    label: year.AccountYear
  }));

  return (
    <header>
      <div className="top-right-navbar">
      <Link to="/define-import">
            <button className="Import-button" onClick={() => console.log('Import')}>Definer importkilde</button>
      </Link>
        {customers.length > 0 ? (
          <>
            <Select
              className="customer-select"
              options={customerOptions}
              onChange={handleCustomerSelect}
              value={customerOptions.find(option => option.value === selectedCustomer)}
              placeholder="Velg klient"
              isSearchable // Enables searching
            />
            {loadingYears ? ( // Show loading indicator
              <p>Laster år...</p>
            ) : availableYears.length > 0 ? (
              <Select
                className="year-select"
                options={yearOptions}
                onChange={handleYearSelect}
                value={yearOptions.find(option => option.value === selectedYear)}
                placeholder="Velg år"
                isSearchable // Enables searching
              />
            ) : (
              <p>Ingen år tilgjengelig</p>
            )}
          </>
        ) : (
          <p>Ingen klienter tilgjengelig</p> // Display message when no customers exist
        )}
        <button className="new-button" onClick={() => console.log('create new customer')}>Opprett ny Klient</button>
        <button className="info-button" onClick={() => console.log('Show info modal')}>Hjelp</button>
              <button className="profile-button" onClick={() => setShowProfileMenu(prev => !prev)}>⏷</button>
        {showProfileMenu && (
          <div className="profile-menu">
            <p>BrukerID: {userId}</p>
            <p>For klient: {selectedCustomer || 'Ingen'}</p>
            <p>År: {selectedYear || 'Ingen'}</p>
            <button onClick={handleLogout}>Logg av</button>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
