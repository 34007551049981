import React, { useState, useEffect } from 'react';
import styles from './ConfirmationModal.module.css';




const ConfirmationModal = ({ isOpen, onClose, onConfirm, onCancel, jobId, modalData, loading, accountData, resetFields, saveChanges }) => {
    const [activeTab, setActiveTab] = useState('Detaljer');
    const [selectedKonto, setSelectedKonto] = useState({});
    const [searchTerms, setSearchTerms] = useState({});
    const [focusedField, setFocusedField] = useState(null);

    if (!isOpen) return null;


    const standardkontoOptions = modalData.standardKontoplanData;

    const handleTabChange = (tab) => {
        if (tab === 'Endringer') {
            saveChanges(); // Call saveChanges when switching to the "Endringer" tab
        }
        setActiveTab(tab);
    };

    const handleStandardKontoChange = (index, value, option = null) => {
        const updatedAccountData = [...modalData.accountData];

        // Update the selected standard account (option details if available)
        updatedAccountData[index][2] = value;
        if (option) {
            updatedAccountData[index][8] = option.Category;
            updatedAccountData[index][9] = option.Classification;
            updatedAccountData[index][7] = option.Scope;
            updatedAccountData[index][6] = option.Factor;
            updatedAccountData[index][10] = option.Notes;
        }

        setSelectedKonto((prev) => ({ ...prev, [index]: value }));
        setSearchTerms((prev) => ({ ...prev, [index]: value }));
    };

    const filteredOptions = (index) => {
      const searchTerm = searchTerms[index]?.toLowerCase() || '';

      // Check if standardkontoOptions is defined and is an array
      if (!Array.isArray(standardkontoOptions)) {
          console.error("standardkontoOptions is not defined or not an array");
          return [];
      }

      return standardkontoOptions.filter(option =>
          option.Description.toLowerCase().includes(searchTerm) ||
          option.AccountID.toString().includes(searchTerm)
      );
  };



    return (
        <div className={styles.modal}>
            <div className={styles.modalContent}>
                <h2>Bekreft import</h2>
                <p>Er du sikker på at du vil fortsette med importen for opplastning nr: {jobId}?</p>

                <div className={styles.tabButtons}>
                    <button onClick={() => handleTabChange('Detaljer')}>Detaljer</button>
                    <button onClick={() => handleTabChange('Kontoplan')}>Kontoplan</button>
                    <button onClick={() => handleTabChange('Endringer')}>Gjennomgå endringer</button>
                </div>

                {loading ? (
                    <div className={styles.loading}>Laster data, vennligst vent...</div>
                ) : (
                    <>
                        {activeTab === 'Detaljer' && (
                            <div className={styles.formContent}>
                                <div>
                                    <label>Navn:</label>
                                    <input type="text" value={modalData.name} readOnly />
                                </div>
                                <div>
                                    <label>Organisasjonsnummer:</label>
                                    <input type="text" value={modalData.organisasjonsnummer} readOnly />
                                </div>
                                <div>
                                    <label>År:</label>
                                    <input type="text" value={modalData.year} readOnly />
                                </div>
                                <div>
                                    <label>Klientadministrasjon:</label>
                                    <input type="text" value={modalData.clientAdmin} readOnly />
                                </div>
                                <div>
                                    <label>Fakturaadresse:</label>
                                    <input type="text" value={modalData.billingAddress} />
                                </div>
                                <div>
                                    <label>E-post for fakturaer:</label>
                                    <input type="text" value={modalData.email} />
                                </div>
                            </div>
                        )}

                        {activeTab === 'Endringer' && (
                            <div className="tab-content">
                                <h3>Endringer</h3>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Endringer:</td>
                                            <td>Ikke på plass enda</td>
                                        </tr>
                                        <tr>
                                            <td>Ønsker å ha</td>
                                            <td>tabell som viser nye transaksjoner</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}

                        {activeTab === 'Kontoplan' && (
    <div className={styles.tabContent}>
        <h3>Kontoplan</h3>
        {modalData.accountData && modalData.accountData.length > 0 ? (
            <table className={styles.kontoplanTable}>
                <thead>
                    <tr>
                        <th>Kontonummer</th>
                        <th>Kontonavn</th>
                        <th>Standardkonto</th>
                        <th>Kategori</th>
                        <th>Klasse</th>
                        <th>Scope</th>
                        <th>Faktor</th>
                        <th>Dok</th>
                    </tr>
                </thead>
                <tbody>
                    {modalData.accountData.map((account, index) => (
                        <tr key={index}>
                            <td>{account[0]}</td>
                            <td>{account[1]}</td>
                            <td>
    <div style={{ position: 'relative' }}>
        <input
            type="text"
            value={
                focusedField === index
                    ? searchTerms[index] || ''
                    : selectedKonto[index] || `${account[2]} ${account[3]}`
            }
            onFocus={() => {
                setFocusedField(index);
                setSearchTerms((prev) => ({ ...prev, [index]: '' }));
            }}
            onBlur={() => setFocusedField(null)}
            onChange={(e) => {
                const searchTerm = e.target.value;
                setSearchTerms((prev) => ({ ...prev, [index]: searchTerm }));
            }}
        />
        {searchTerms[index] && (
            <ul className={styles.dropdown}>
                {filteredOptions(index).map((option, i) => (
                    <li key={i} onClick={() => {
                        const selectedValue = `${option.AccountID} ${option.Description}`;
                        handleStandardKontoChange(index, selectedValue, option);
                        setSearchTerms((prev) => ({ ...prev, [index]: '' }));
                    }}>
                        {option.AccountID} {option.Description}
                    </li>
                ))}
            </ul>
        )}
    </div>
</td>
                            <td>{account[8]}</td>
                            <td>{account[9]}</td>
                            <td>{account[7]}</td>
                            <td>{account[6]}</td>
                            <td>{account[10]}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        ) : (
            <p>Ingen kontoer tilgjengelig.</p>
        )}
    </div>
)}


                        <div className={styles.modalActions}>
                        <button onClick={onConfirm} disabled={loading}>Bekreft</button>
                <button onClick={onCancel} disabled={loading}>Avbryt</button>
                <button onClick={onClose} disabled={loading}>Lukk</button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ConfirmationModal;
