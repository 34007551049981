import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import './Utslippsfaktorer.css';

const Utslippsfaktorer = () => {
    const userRole = localStorage.getItem('userRole'); // Get the user role from local storage
    const userId = localStorage.getItem('userId');

    return (
        <div className="administration-container">
            <h1>Utslippsfaktorer</h1>
            <p> Here we connect to the db, show a dropdown of all the years we can find for the kontoplan tables. On change we load the new table from the db.</p>
            <p> We should be able to see all accounts and search both by name and accountnumber/id </p>
            <p> We need to save the changes if there is changed anyhting.</p>
            <p> A button for creating new accounts</p>
            <h1>We want to open a modal for editing or creating the accounts.</h1>
            <p> Modal should include all the fields nesessary to make a row in the db:
             AccountID
              Category
              Classification
              Scope
              Factor
              Calculation
              Notes
              Name
              Excluded
              StdAccount
              Description
              doc
              </p>
             <p>Buttons for saving changes or cancel </p>
        </div>
    );
};

export default Utslippsfaktorer;
