import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import UploadSAFTFile from './pages/UploadSAFTFile';
import Administration from './pages/Administration';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Breadcrumb from './components/Breadcrumb';
import Kontoplan from './components/Kontoplan';
import { AppProvider } from './components/AppContext';
import CustomerList from './components/CustomerList';
import Supplier from './components/CustomerList';
import Hovedbok from './components/Hovedbok';
import Customerinfo from './components/customerinfo';
import SupplierDetail from './pages/SupplierDetail';
import Rapporter from './pages/Rapporter';
import Energy from './pages/Energy';
import Klientadministration from './pages/Klientadministration';
import Utslippsfaktorer from './pages/Utslippsfaktorer';
import UploadTripletex from './pages/UploadTripletex';
import NewClient from './pages/NewClient';
import DefineImport from './pages/DefineImport';


import './App.css';

function PrivateRoute({ children }) {
    const isLoggedIn = !!localStorage.getItem('isLoggedIn');
    return isLoggedIn ? children : <Navigate to="/login" />;
}

function App() {
    const isLoggedIn = !!localStorage.getItem('isLoggedIn');

    return (
        <AppProvider>
            <Router>
                <div className="app">
                    {isLoggedIn && <Sidebar />}
                    <div className="main-content">
                        {isLoggedIn && <Header />}
                        {isLoggedIn && <Breadcrumb />}
                        <main>
                            <Routes>
                                <Route path="/login" element={<LoginPage />} />
                                <Route path="/register" element={<RegisterPage />} />
                                <Route path="/upload-saft" element={<PrivateRoute><UploadSAFTFile /></PrivateRoute>} />
                                <Route path="/kontoplan" element={<PrivateRoute><Kontoplan /></PrivateRoute>} />
                                <Route path="/info" element={<PrivateRoute><Customerinfo /></PrivateRoute>} />
                                <Route path="/suppliers" element={<PrivateRoute><CustomerList /></PrivateRoute>} />
                                <Route path="/supplier" element={<PrivateRoute><Supplier /></PrivateRoute>} />
                                <Route path="/rapporter" element={<PrivateRoute><Rapporter /></PrivateRoute>} />
                                <Route exact path="/supplier/:id" element={<PrivateRoute><SupplierDetail /></PrivateRoute>} />
                                <Route path="/energy" element={<PrivateRoute><Energy /></PrivateRoute>} />
                                <Route path="/administrasjon" element={<PrivateRoute><Administration /></PrivateRoute>} />
                                <Route path="/klientadministration" element={<PrivateRoute><Klientadministration /></PrivateRoute>} />
                                <Route path="/Utslippsfaktorer" element={<PrivateRoute><Utslippsfaktorer /></PrivateRoute>} />
                                <Route path="/UploadTripletex" element={<PrivateRoute><UploadTripletex /></PrivateRoute>} />
                                <Route path="/hovedbok" element={<PrivateRoute><Hovedbok /></PrivateRoute>} />
                                <Route path="/newclient" element={<PrivateRoute><NewClient /></PrivateRoute>} />
                                <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                                <Route path="/define-import" element={<PrivateRoute><DefineImport /></PrivateRoute>} />

                            </Routes>
                        </main>
                    </div>
                </div>
            </Router>
        </AppProvider>
    );
}

export default App;
