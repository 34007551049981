// LoginPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import './LoginPage.css'; // Import the CSS file for styling

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://app.ecofocus.no:3443/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('userRole', data.role);
        localStorage.setItem('userId', data.userId);
        window.location.href = '/';
      } else {
        const errorMessage = await response.text();
        setError(errorMessage);
      }
    } catch (err) {
      console.error('Login error:', err);
      setError('En feil har oppstått. Vennligst prøv igjen.');
    }
  };

  const handleRegister = () => {
     // Redirect to the registration page
     navigate('/register');
   };

  return (
    <div className="login-page">
      <div className="login-container">
        <h2>Logg inn</h2>
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label>Epost:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="form-input"
            />
          </div>
          <div className="form-group">
            <label>Passord:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="form-input"
            />
          </div>
          <button type="submit" className="login-button">Logg inn</button>
          {error && <p className="error-message">{error}</p>}
        </form>
        <button onClick={handleRegister} className="register-button">
         Opprett ny bruker
       </button>
      </div>
    </div>
  );
};

export default LoginPage;
