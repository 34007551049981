import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './RapporterStyles.css';

// Static reports data
const reportsData = {
    "GHG-Rapporter": [
        { title: "GHG-Rapport (Årlig)", id: 1 },
        { title: "GHG-Rapport (Månedlig)", id: 2 },
        { title: "GHG-Rapport Avansert (Årlig)", id: 3 },
    ],
    "Klimarapporter": [
        { title: "Klimarapport (Årlig)", id: 12 },
    ],
  //  "ESRS-Rapporter": [
  //      { title: "ESRS-Rapport", id: 4 },
  //      { title: "LSME-Rapport", id: 5 },
  //      { title: "VSME-Rapport", id: 6 },
  //  ],
  //  "NSRS-Rapporter": [
  //      { title: "Level 1 NSRS-Rapport", id: 7 },
  //      { title: "Level 2 NSRS-Rapport", id: 8 },
  //  ],
    "Dokumentasjon": [
        { title: "GHG Dokumentasjon", id: 9 },
        { title: "Leverandører", id: 10 },
        { title: "Datagrunnlag", id: 11 },
    ]
};

// Initial state for created reports
const initialCreatedReports = [];

const months = [
    "Januar", "Februar", "Mars", "April", "Mai", "Juni",
    "Juli", "August", "September", "Oktober", "November", "Desember"
];

const Rapporter = () => {
    const customerOrgNr = localStorage.getItem('selectedCustomer') || '';
    const [year, setYear] = useState(localStorage.getItem('selectedYear') || '');
    const [modalReport, setModalReport] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState('');
    const [fromSupplier, setFromSupplier] = useState('');
    const [toSupplier, setToSupplier] = useState('');
    const [includeEmissions, setIncludeEmissions] = useState(false);
    const [includeScope1And2, setIncludeScope1And2] = useState(false);
    const [noEmissions, setNoEmissions] = useState(false);
    const [suppliers, setSuppliers] = useState([]);
    const [createdReports, setCreatedReports] = useState(initialCreatedReports);

    // Fetch created reports
    const fetchCreatedReports = async () => {
        console.log(customerOrgNr);
        try {
            const response = await fetch(`https://app.ecofocus.no:3443/api/createreport/generated-reports?customerOrgNr=${customerOrgNr}&year=${year}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch created reports');
            }

            const result = await response.json();
            setCreatedReports(result);
        } catch (error) {
            console.error('Error fetching created reports:', error);
        }
    };

    // UseEffect to fetch created reports on mount
    useEffect(() => {
        fetchCreatedReports();
    }, [customerOrgNr, year]); // Re-fetch reports when customerOrgNr or year changes

    // Fetch suppliers based on customer org number and year
    useEffect(() => {
        const fetchSuppliers = async () => {
            try {
                const response = await fetch('https://app.ecofocus.no:3443/api/listofsuppliersreportRoute/suppliers', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ customerOrgNr, year }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const result = await response.json();
                const supplierOptions = result.data.map(supplier => ({
                    value: supplier.CustomerSupplierID,
                    label: supplier.Name,
                }));

                setSuppliers(supplierOptions);
            } catch (error) {
                console.error('Error fetching suppliers:', error);
            }
        };

        fetchSuppliers();
    }, [customerOrgNr, year]);

    const openModal = (report) => {
        setModalReport(report);
        setSelectedMonth('');
        setFromSupplier('');
        setToSupplier('');
        setIncludeEmissions(false);
        setIncludeScope1And2(false);
        setNoEmissions(false);
    };

    const closeModal = () => {
        setModalReport(null);
    };

    const createReport = async () => {
        if (modalReport.id === 2 && !selectedMonth) {
            alert('Vennligst velg en måned.');
            return;
        }

        const reportData = {
            customerOrgNr,
            year,
            reportTitle: modalReport.title,
            month: modalReport.id === 2 ? selectedMonth : null,
            fromSupplier,
            toSupplier,
            includeEmissions,
            includeScope1And2,
            noEmissions
        };

        try {
            const response = await fetch('https://app.ecofocus.no:3443/api/createreport/create-report', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reportData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            console.log(result.message);

            // Fetch updated reports after creation
            await fetchCreatedReports();
            closeModal();
        } catch (error) {
            console.error('Error creating report:', error);
        }
    };

    const handleOverlayClick = (e) => {
        if (e.target.classList.contains('modal-overlay')) {
            closeModal();
        }
    };

    // Function to download the report
    const downloadReport = async (reportId, year, client) => {
        try {
            const response = await fetch(`https://app.ecofocus.no:3443/api/createreport/download-report/${reportId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to download report');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${client}_rapport_${reportId}_${year}.pdf`); // Change the file name and extension if needed
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('Error downloading report:', error);
        }
    };
    // Function to delete a report
        const deleteReport = async (reportId) => {
            const confirmDelete = window.confirm('Er du sikker på at du vil slette denne rapporten?');
            if (!confirmDelete) return;

            try {
                const response = await fetch(`https://app.ecofocus.no:3443/api/createreport/delete-report/${reportId}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to delete report');
                }

                // Fetch updated reports after deletion
                await fetchCreatedReports();
            } catch (error) {
                console.error('Error deleting report:', error);
            }
        };

    return (
        <div className="rapporter-container">
            <div className="reports-section">
                {Object.entries(reportsData).map(([category, reports]) => (
                    <div key={category} className="category-section">
                        <h2 className="category-title">{category}</h2>
                        <div className="reports-inline">
                            {reports.map(report => (
                                <div className="report-card" key={report.id}>
                                    <h3 className="report-title">{report.title}</h3>
                                    <button
                                        className="view-report-btn"
                                        onClick={() => openModal(report)}
                                    >
                                        Lag Rapport
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>

            <div className="created-reports-section">
                <h2 className="created-reports-title">Bestilte Rapporter</h2>
                <table className="reports-table">
                    <thead>
                        <tr>
                            <th>Rapport</th>
                            <th>Dato bestilt</th>
                            <th>Rapport år</th>
                            <th>Periode</th>
                            <th>Handling</th>
                        </tr>
                    </thead>
                    <tbody>
                        {createdReports.map((report, index) => (
                            <tr key={index}>
                                <td>{report.report_title}</td>
                                <td>{new Date(report.generated_at).toLocaleDateString()}</td>
                                <td>{report.year}</td>
                                <td>{report.month}</td>
                                <td>
                                    <button className="download-btn" onClick={() => downloadReport(report.id, year, customerOrgNr)}>Last ned</button>
                                    <button className="delete-btn" onClick={() => deleteReport(report.id)}>Slett</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {modalReport && (
                <div className="modal-overlay" onClick={handleOverlayClick}>
                    <div className="modal">
                        <h2>Lag {modalReport.title}</h2>
                        <p>Kunde: {customerOrgNr}</p>
                        <p>År: {year}</p>

                        {modalReport.id === 2 && (
                            <div>
                                <label htmlFor="month-select">Velg måned:</label>
                                <select
                                    id="month-select"
                                    value={selectedMonth}
                                    onChange={(e) => setSelectedMonth(e.target.value)}
                                >
                                    <option value="">Velg en måned</option>
                                    {months.map((month, index) => (
                                        <option key={index} value={month}>{month}</option>
                                    ))}
                                </select>
                            </div>
                        )}

                        <div>
                            <label htmlFor="from-supplier">Fra Leverandør:</label>
                            <Select
                                id="from-supplier"
                                options={suppliers}
                                value={suppliers.find(s => s.value === fromSupplier)}
                                onChange={(option) => setFromSupplier(option.value)}
                                isClearable
                            />
                        </div>

                        <div>
                            <label htmlFor="to-supplier">Til Leverandør:</label>
                            <Select
                                id="to-supplier"
                                options={suppliers}
                                value={suppliers.find(s => s.value === toSupplier)}
                                onChange={(option) => setToSupplier(option.value)}
                                isClearable
                            />
                        </div>

                        <div>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={includeEmissions}
                                    onChange={() => setIncludeEmissions(!includeEmissions)}
                                />
                                Inkluder utslipp
                            </label>
                        </div>

                        <div>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={includeScope1And2}
                                    onChange={() => setIncludeScope1And2(!includeScope1And2)}
                                />
                                Inkluder Scope 1 og 2
                            </label>
                        </div>

                        <div>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={noEmissions}
                                    onChange={() => setNoEmissions(!noEmissions)}
                                />
                                Ingen utslipp
                            </label>
                        </div>

                        <div className="modal-buttons">
                            <button onClick={createReport}>Lag Rapport</button>
                            <button onClick={closeModal}>Avbryt</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Rapporter;
