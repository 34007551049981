import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './upload.css';
import ConfirmationModal from './ConfirmationModal'; // Import the modal

const UploadSAFTFile = () => {
    const [file, setFile] = useState(null);
    const [jobId, setJobId] = useState(null);
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [pendingJobs, setPendingJobs] = useState([]); // New state for pending jobs
    const [modalOpen, setModalOpen] = useState(false); // State for modal visibility
    const [currentJobId, setCurrentJobId] = useState(null); // New state for current job ID
    const userId = localStorage.getItem('userId');
    const [modalData, setModalData] = useState({}); // New state for modal data
        const [accountData, setAccountData] = useState([]); // New state for account data
    const [error, setError] = useState(''); // State for error messages
    const [modalLoading, setModalLoading] = useState(false); // New loading state for modal
    const [modalClosing, setModalClosing] = useState(false); // New state for modal closing loading
    const [selectedKonto, setSelectedKonto] = useState({});
    const [searchTerms, setSearchTerms] = useState({});
    const [focusedField, setFocusedField] = useState(null);

    const resetFields = () => {
        setFocusedField(null);
        setSelectedKonto({});
        setSearchTerms({});
        setSelectedKonto({});
    };

        // Function to fetch data for the modal
        const fetchJobData = async (jobId) => {
            setModalLoading(true); // Set loading state to true
            try {
                const response = await axios.get(`https://app.ecofocus.no:3443/api/upload/jobdata/${jobId}`);
                setModalData(response.data);
            } catch (error) {
                console.error('Error fetching job data:', error);
                setError('Could not fetch job data.');
            } finally {
                setModalLoading(false); // Reset loading state after fetch
            }
        };


        const saveChanges = async () => {
            try {
                // Send updated data to the backend
                await axios.post(`https://app.ecofocus.no:3443/api/upload/saveKontoplanChanges/${currentJobId}`, {
                    kontoplanData: modalData.accountData, // Pass the current kontoplan data
                    name: modalData.name,
                    orgnr: modalData.organisasjonsnummer,
                    year: modalData.year,
                });
                console.log('Changes saved successfully');
            } catch (error) {
                console.error('Error saving changes:', error);
                setError('Error saving changes.');
            }
        };



    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            if (selectedFile.type === 'application/xml' || selectedFile.name.endsWith('.xml')) {
                setFile(selectedFile);
            } else {
                setError('Please upload a valid XML file.');
                setFile(null);
            }
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const droppedFile = event.dataTransfer.files[0];
        if (droppedFile) {
            if (droppedFile.type === 'application/xml' || droppedFile.name.endsWith('.xml')) {
                setFile(droppedFile);
            } else {
                setError('Please upload a valid XML file.');
            }
        }
    };

    const handleUpload = async () => {
        if (!file) {
            setError('Please select a file to upload.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        setLoading(true);
        setError('');

        try {
            const response = await axios.post(`https://app.ecofocus.no:3443/api/upload/saft/${userId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            const { jobId } = response.data;
            setJobId(jobId);
            setStatus('needs_confirmation');
        } catch (error) {
            console.error('Error uploading file:', error);
            setError('Error uploading file.');
        } finally {
            setLoading(false);
        }
    };

    const confirmUpload = async (jobId) => {
        if (!jobId) return;

        try {
            const response = await axios.post(`https://app.ecofocus.no:3443/api/upload/confirm/${jobId}`);
            setStatus(response.data.message);
            fetchPendingJobs(); // Refresh pending jobs after confirmation
        } catch (error) {
            console.error('Error confirming job:', error);
            setError('Error confirming job.');
        }
    };

    const cancelUpload = async (jobId) => {
        if (!jobId) return;

        try {
            await axios.post(`https://app.ecofocus.no:3443/api/upload/cancel/${jobId}`);
            setJobId(null);
            setFile(null);
            setStatus('canceled');
            alert('Upload canceled successfully.');
            fetchPendingJobs();
        } catch (error) {
            console.error('Error canceling job:', error);
            setError('Error canceling job.');
        }
    };

    const checkJobStatus = async (jobId) => {
        try {
            const response = await axios.get(`https://app.ecofocus.no:3443/api/upload/status/${jobId}`);
            setStatus(response.data.status);
        } catch (error) {
            console.error('Error checking job status:', error);
            setError('Error checking job status.');
        }
    };

    const fetchPendingJobs = async () => {
        try {
            const response = await axios.get(`https://app.ecofocus.no:3443/api/upload/pending/${userId}`);
            setPendingJobs(response.data.pendingJobs); // Assume the response contains an array of pending jobs
        } catch (error) {
            console.error('Error fetching pending jobs:', error);
            setError('Error fetching pending jobs.');
        }
    };

    const handleJobAction = async (action) => {
        if (action === 'confirm') {
              setModalClosing(true); // Set loading state to true before async operations
            await saveChanges();
             resetFields();
            confirmUpload(currentJobId);
                setModalClosing(false); // Reset loading state after operations are done

        } else if (action === 'cancel') {
            await saveChanges();
             resetFields();
            cancelUpload(currentJobId);
        }
        setModalOpen(false);
        setTimeout(() => {
        window.location.reload(); // Reload after state has updated
    }, 100); // Small delay to ensure state update is completed
    };
    const handleCloseModal = async () => {
        setModalClosing(true); // Set loading state to true before async operations
    await saveChanges();
     resetFields();
    setModalOpen(false);
      setModalClosing(false); // Reset loading state after operations are done
      setTimeout(() => {
      window.location.reload(); // Reload after state has updated
  }, 100); // Small delay to ensure state update is completed
};


    const showConfirmationModal = (jobId) => {
         resetFields();
        setCurrentJobId(jobId);
        fetchJobData(jobId); // Fetch data before opening the modal
        setModalOpen(true);
    };

    useEffect(() => {
        fetchPendingJobs(); // Fetch pending jobs on mount
  fetchJobData(jobId);
        if (jobId && status !== 'completed' && status !== 'failed') {
            const interval = setInterval(() => {
                checkJobStatus(jobId);
            }, 5000);

            return () => clearInterval(interval);
        }
    }, [jobId, status]);

    return (
        <main className="upload-container" onDragOver={handleDragOver} onDrop={handleDrop}>
            <div className="card">
                <h2>Last opp SAF-T fil</h2>
                <div className="drop-area" onClick={() => document.getElementById('file-input').click()}>
                    <input
                        type="file"
                        id="file-input"
                        onChange={handleFileChange}
                        accept=".xml"
                        style={{ display: 'none' }}
                    />
                    <p>Dra og slipp filen her eller klikk for å velge fil.</p>
                    {file && <p>Filnavn: {file.name}</p>}

                </div>
                <button onClick={handleUpload} disabled={loading}>
                    {loading ? 'Laster opp...' : 'Last opp'}
                </button>
            </div>

            {jobId && (
                <div className="card">
                    <h2>Status på opplastning</h2>
                    <p>Opplastning nr: {jobId}</p>
                    <p>Status: {loading ? 'Laster opp, vennligst vent...' : status}</p>
                    {status === 'needs_confirmation' && (
                        <button onClick={() => showConfirmationModal(jobId)}>Fortsett import</button>
                    )}
                </div>
            )}

            {/* Display pending jobs */}
            {pendingJobs.length > 0 && (
                <div className="card">
                    <h2>Ubehandlede opplastinger</h2>
                    {pendingJobs.map((job) => (
                        <div key={job.jobId}>
                            <p>Opplastning nr: {job.jobId}</p>
                            <p>Status: {job.status}</p>
                            <button onClick={() => showConfirmationModal(job.jobId)}>Fortsett import</button>
                        </div>
                    ))}
                </div>
            )}

            <div className="card">
                <h2>Last opp saldobalanse</h2>
                <p>Dette alternativet er ikke tilgjengelig. Kommer snart.</p>
                <button disabled>Under utvikling</button>
            </div>

            {/* Confirmation Modal */}
            {/* Confirmation Modal */}
            <ConfirmationModal
             isOpen={modalOpen}
             onClose={handleCloseModal} // Save on close
             onConfirm={() => handleJobAction('confirm')}
             onCancel={() => handleJobAction('cancel')}
             jobId={currentJobId}
             modalData={modalData}
             loading={modalLoading || modalClosing} // Combine both loading states
             accountData={accountData}
             resetFields={resetFields}
             saveChanges={saveChanges}
              />
        </main>
    );
};

export default UploadSAFTFile;
