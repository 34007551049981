import React, { useState } from 'react';
import './Energy.css'; // Add some CSS for styling if needed

const dummyData = [
    {
        id: 1,
        accountNumber: '6340',
        date: '01.01.2023',
        accountName: 'Strøm',
        kWh: 0,
        calculatedCO2e: 0,
        factor: 0,
        calculated: 0,
        month: "January",
        opprinnelsesgarantier: false,
    },
    {
        id: 2,
        accountNumber: '6341',
        date: '01.02.2023',
        accountName: 'Strøm annet',
        kWh: 0,
        calculatedCO2e: 0,
        factor: 0,
        calculated: 0,
        month: "February",
        opprinnelsesgarantier: false,
    },
    // Add more dummy data as needed
];

const Energy = () => {
    const [viewType, setViewType] = useState('Per month');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [location, setLocation] = useState('');
    const [transactions, setTransactions] = useState(dummyData);
    const [selectedTransactions, setSelectedTransactions] = useState([]);
    const [monthData, setMonthData] = useState([{ month: '', kWh: '', calculatedCO2e: '', factor: '', calculated: '', opprinnelsesgarantier: false }]);
    const [opprinnelsesgarantier, setOpprinnelsesgarantier] = useState(false);
    const [utslippsfaktor, setUtslippsfaktor] = useState('');

    const handleAddMonth = () => {
        setMonthData([...monthData, { month: '', kWh: '', calculatedCO2e: '', factor: '', calculated: '', opprinnelsesgarantier: false }]);
    };

    const handleMonthChange = (index, field, value) => {
        const updatedMonthData = [...monthData];
        updatedMonthData[index][field] = value;
        setMonthData(updatedMonthData);
    };

    const handleSelectTransaction = (transaction) => {
        if (selectedTransactions.includes(transaction)) {
            setSelectedTransactions(selectedTransactions.filter(t => t !== transaction));
        } else {
            setSelectedTransactions([...selectedTransactions, transaction]);
        }
    };

    return (
        <div className="energy-page">
            <div className="filters">
                <select value={viewType} onChange={(e) => setViewType(e.target.value)}>
                    <option value="Per month">Per month</option>
                    <option value="Total Year">Total Year</option>
                </select>
                <input
                    type="text"
                    placeholder="Search Location"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                />
                <label>
                    <input
                        type="checkbox"
                        checked={opprinnelsesgarantier}
                        onChange={(e) => {
                            setOpprinnelsesgarantier(e.target.checked);
                            if (e.target.checked) {
                                setUtslippsfaktor(''); // Reset utslippsfaktor if unchecked
                            }
                        }}
                    />
                    Opprinnelsesgarantier
                </label>
                {opprinnelsesgarantier && (
                    <input
                        type="text"
                        placeholder="Utslippsfaktor"
                        value={utslippsfaktor}
                        onChange={(e) => setUtslippsfaktor(e.target.value)}
                    />
                )}
            </div>

            {viewType === 'Per month' && (
                <div className="month-inputs">
                    {monthData.map((data, index) => (
                        <div key={index} className="month-input">
                            <select
                                value={data.month}
                                onChange={(e) => handleMonthChange(index, 'month', e.target.value)}
                            >
                                <option value="">Select Month</option>
                                <option value="January">January</option>
                                <option value="February">February</option>
                                <option value="March">March</option>
                                {/* Add more months as needed */}
                            </select>
                            <input
                                type="number"
                                placeholder="kWh"
                                value={data.kWh}
                                onChange={(e) => handleMonthChange(index, 'kWh', e.target.value)}
                            />
                            <input
                                type="number"
                                placeholder="Calculated CO2e"
                                value={data.calculatedCO2e}
                                onChange={(e) => handleMonthChange(index, 'calculatedCO2e', e.target.value)}
                            />
                            <input
                                type="number"
                                placeholder="Factor"
                                value={data.factor}
                                onChange={(e) => handleMonthChange(index, 'factor', e.target.value)}
                            />
                            <input
                                type="number"
                                placeholder="Calculated"
                                value={data.calculated}
                                onChange={(e) => handleMonthChange(index, 'calculated', e.target.value)}
                            />
                            <label>
                                <input
                                    type="checkbox"
                                    checked={data.opprinnelsesgarantier}
                                    onChange={(e) => handleMonthChange(index, 'opprinnelsesgarantier', e.target.checked)}
                                />
                                Opprinnelsesgarantier
                            </label>
                        </div>
                    ))}
                    <button onClick={handleAddMonth}>+</button>
                </div>
            )}

            <div className="search">
                <input type="text" placeholder="Search Account Number" />
                <input type="text" placeholder="Search Account Name" />
            </div>

            <table className="transactions-table">
                <thead>
                    <tr>
                        <th>Account Number</th>
                        <th>Account Name</th>
                        <th>Dato</th>
                        <th>kWh</th>
                        <th>Calculated CO2e</th>
                        <th>Factor</th>
                        <th>Calculated</th>
                        <th>For month</th>
                        <th>Opprinnelsesgarantier</th>
                        <th>Select</th>
                    </tr>
                </thead>
                <tbody>
                    {transactions.map((transaction) => (
                        <tr key={transaction.id} className={selectedTransactions.includes(transaction) ? 'selected' : ''}>
                            <td>{transaction.accountNumber}</td>
                            <td>{transaction.date}</td>
                            <td>{transaction.accountName}</td>
                            <td>{transaction.kWh}</td>
                            <td>{transaction.calculatedCO2e}</td>
                            <td>{transaction.factor}</td>
                            <td>{transaction.calculated}</td>
                            <td>{transaction.month}</td>
                            <td>{transaction.opprinnelsesgarantier ? 'Yes' : 'No'}</td>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={selectedTransactions.includes(transaction)}
                                    onChange={() => handleSelectTransaction(transaction)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <h3>Selected Transactions</h3>
            <table className="selected-transactions-table">
                <thead>
                    <tr>
                        <th>Account Number</th>
                        <th>Dato</th>
                        <th>Account Name</th>
                        <th>kWh</th>
                        <th>Calculated CO2e</th>
                        <th>Factor</th>
                        <th>Calculated</th>
                        <th>For month</th>
                        <th>Opprinnelsesgarantier</th>
                    </tr>
                </thead>
                <tbody>
                    {selectedTransactions.map((transaction) => (
                        <tr key={transaction.id}>
                            <td>{transaction.accountNumber}</td>
                            <td>{transaction.date}</td>
                            <td>{transaction.accountName}</td>
                            <td>{transaction.kWh}</td>
                            <td>{transaction.calculatedCO2e}</td>
                            <td>{transaction.factor}</td>
                            <td>{transaction.calculated}</td>
                            <td>{transaction.month}</td>
                            <td>{transaction.opprinnelsesgarantier ? 'Yes' : 'No'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Energy;
