import React, { useState, useEffect } from 'react'; // Import hooks
import './Klientadministration.css';

const UploadTripletex = () => {
    const [suppliers, setSuppliers] = useState([]); // State to hold suppliers data
    const [hovedbok, setHovedbok] = useState([]); // State to hold hovedbok data
    const [error, setError] = useState(null); // State to hold error messages
    const [loading, setLoading] = useState({ suppliers: true, hovedbok: true }); // Loading states for both fetches
    const [kontoplan, setKontoplan] = useState([]); // State to hold hovedbok data

    const userRole = localStorage.getItem('userRole'); // Get the user role from local storage
    const userId = localStorage.getItem('userId');

    // Fetch suppliers
    useEffect(() => {
        const fetchSuppliers = async () => {
            try {
                const response = await fetch('https://app.ecofocus.no:3443/api/tripletex/suppliers');

                if (!response.ok) {
                    throw new Error('Failed to fetch suppliers');
                }

                const data = await response.json();
                console.log('Suppliers:', data);
                setSuppliers(data.values); // Update suppliers state with fetched data
            } catch (error) {
                setError(`Suppliers Error: ${error.message}`);
            } finally {
                setLoading(prev => ({ ...prev, suppliers: false }));
            }
        };

        fetchSuppliers();
    }, []); // Empty dependency array ensures fetch runs only once after component mounts

    // Fetch hovedbok
    useEffect(() => {
        const fetchHovedbok = async () => {
            try {
                const response = await fetch(
                    'https://app.ecofocus.no:3443/api/tripletex/hovedbok?dateFrom=2024-01-01&dateTo=2024-12-31'
                );

                if (!response.ok) {
                    throw new Error('Failed to fetch hovedbok');
                }

                const data = await response.json();
                console.log('Hovedbok:', data);
                setHovedbok(data.values); // Update hovedbok state with fetched data
            } catch (error) {
                setError(`Hovedbok Error: ${error.message}`);
            } finally {
                setLoading(prev => ({ ...prev, hovedbok: false }));
            }
        };

        fetchHovedbok();
    }, []); // Empty dependency array ensures fetch runs only once after component mounts

    // Fetch hovedbok
    useEffect(() => {
        const fetchKontoplan = async () => {
            try {
                const response = await fetch(
                    'https://app.ecofocus.no:3443/api/tripletex/kontoplan?dateFrom=2024-01-01&dateTo=2024-12-31'
                );

                if (!response.ok) {
                    throw new Error('Failed to fetch hovedbok');
                }

                const data = await response.json();
                console.log('kontoplan:', data);
                setKontoplan(data.values); // Update hovedbok state with fetched data
            } catch (error) {
                setError(`Kontoplan Error: ${error.message}`);
            } finally {
                setLoading(prev => ({ ...prev, Kontoplan: false }));
            }
        };

        fetchKontoplan();
    }, []); // Empty dependency array ensures fetch runs only once after component mounts

    return (
        <div className="administration-container">
            <h1>Importer fra Tripletex</h1>
            <p>Dette skal skje i backend.</p>

            {/* Show error if any */}
            {error && <p className="error-message">Error: {error}</p>}

            {/* Display the fetched suppliers */}
            <h2>Suppliers</h2>
            {loading.suppliers ? (
                <p>Loading suppliers...</p>
            ) : (
                <ul className="supplier-list">
                    {suppliers.map((supplier, index) => (
                        <li key={index} className="supplier-item">
                            <strong>{supplier.name}</strong> (ID: {supplier.supplierNumber}) (orgnr: {supplier.organizationNumber})
                        </li>
                    ))}
                </ul>
            )}

            {/* Display the fetched hovedbok data */}
            <h2>Hovedbok (General Ledger)</h2>
            {loading.hovedbok ? (
                <p>Loading hovedbok...</p>
            ) : (
                <table className="hovedbok-table">
                    <thead>
                        <tr>
                            <th>id</th>
                            <th>KontoID</th>
                            <th>Description</th>
                            <th>Beløp</th>
                            <th>Dato</th>
                            <th>Leverandør</th>
                            <th>Avdeling</th>
                            <th>Prosjekt</th>
                        </tr>
                    </thead>
                    <tbody>
                      {hovedbok.map((entry, index) => (
                          <tr key={index}>
                              <td>{entry.id || '-'}</td> {/* Use optional chaining */}
                              <td>{entry.account?.id || '-'}</td>
                              <td>{entry.description || '-'}</td>
                              <td>{entry.amount || '-'}</td>
                              <td>{entry.date || '-'}</td>
                              <td>{entry.supplier?.id || '-'}</td> {/* Handle null supplier */}
                              <td>{entry.department || '-'}</td>
                              <td>{entry.project?.id || '-'}</td> {/* Handle null project */}
                          </tr>
                      ))}
                  </tbody>
                </table>
            )}
        </div>
    );
};

export default UploadTripletex;
