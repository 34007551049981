import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import './Klientadministration.css';

const Klientadministration = () => {
    const userRole = localStorage.getItem('userRole'); // Get the user role from local storage
    const userId = localStorage.getItem('userId');


    return (
        <div className="administration-container">
            <h1>Klientadministrasjon</h1>
            <p> Here we want a table of all the clients (all rows in the table tilgangsstyring) where userid is "userId" and brukeradmin is "1".</p>
            <p> We also want to have a button on each line to "open" the client and see all userids that has the selected clients.</p>
            <p> We also need to have a button for adding other userids to the table.</p>
            <p> Button to invite new users.</p>
        </div>
    );
};

export default Klientadministration;
