import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './RegisterPage.css'; // Import the CSS file for styling

const RegisterPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate

  const handleRegister = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://app.ecofocus.no:3443/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, password }),
      });

      if (response.ok) {
        setSuccess('Bruker registrert! Du kan nå logge inn.');
        setError('');
        // Optionally redirect to login page after a successful registration
        setTimeout(() => {
          navigate('/login');
        }, 2000); // Redirect after 2 seconds
      } else {
        const errorMessage = await response.text();
        setError(errorMessage);
        setSuccess('');
      }
    } catch (err) {
      console.error('Registration error:', err);
      setError('En feil har oppstått. Vennligst prøv igjen.');
      setSuccess('');
    }
  };

  return (
    <div className="register-page">
      <div className="register-container">
        <h2>Registrer ny bruker</h2>
        <form onSubmit={handleRegister}>
          <div className="form-group">
            <label>Navn:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="form-input"
            />
          </div>
          <div className="form-group">
            <label>Epost:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="form-input"
            />
          </div>
          <div className="form-group">
            <label>Passord:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="form-input"
            />
          </div>
          <button type="submit" className="register-button">Opprett</button>
          {error && <p className="error-message">{error}</p>}
          {success && <p className="success-message">{success}</p>}
        </form>
      </div>
    </div>
  );
};

export default RegisterPage;
