import React, { useState, useEffect } from "react";
import "./DefineImport.css"; // Include custom CSS for styling

const DefineImport = () => {
  const [selectedSource, setSelectedSource] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [activeSource, setActiveSource] = useState("");
  const [showImportLog, setShowImportLog] = useState(false); // State to toggle Import Log visibility

  const options = [
    { id: "tripletex", label: "Tripletex", requiresApiKey: true },
    { id: "poweroffice", label: "PowerOffice Go", requiresApiKey: true },
    { id: "24sevenoffice", label: "24SevenOffice", requiresCredentials: true },
    { id: "saf_t_file", label: "SAF-T-fil", requiresApiKey: false },
  ];

  useEffect(() => {
    const fetchActiveSource = async () => {
      try {
        const response = await fetch("https://app.ecofocus.no:3443/api/activeImportSource");
        const data = await response.json();
        setActiveSource(data.activeSource || ""); // Assume backend returns `{ activeSource: "tripletex" }`
        setSelectedSource(data.activeSource || "");
      } catch (error) {
        console.error("Error fetching active import source:", error);
      }
    };

    fetchActiveSource();
  }, []);

  const handleOptionChange = (e) => {
    setSelectedSource(e.target.value);
    setApiKey(""); // Clear API key on source change
    setUsername("");
    setPassword("");
  };

  const handleSave = async () => {
    const payload = { source: selectedSource };

    if (selectedSource === "24sevenoffice") {
      payload.username = username;
      payload.password = password;
    } else if (options.find((opt) => opt.id === selectedSource)?.requiresApiKey) {
      payload.apiKey = apiKey;
    }

    try {
      const response = await fetch("https://app.ecofocus.no:3443/api/saveImportSource", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        alert("Importkilden er lagret!");
        const data = await response.json();
        setActiveSource(data.activeSource);
      } else {
        alert("Kunne ikke lagre importkilden.");
      }
    } catch (error) {
      console.error("Error saving import source:", error);
    }
  };

  return (
    <div className="define-container">
      <h1>Definer Importkilde</h1>
      <div className="import-settings">
        <h2>Importkilde:</h2>
        <div className="options-list">
          {options.map((option) => (
            <label key={option.id} className="option-item">
              <input
                type="radio"
                name="import-source"
                value={option.id}
                onChange={handleOptionChange}
                checked={selectedSource === option.id}
              />
              {option.label}{" "}
              {activeSource === option.id && <span>(pågående aktiv)</span>}
            </label>
          ))}
        </div>

        {selectedSource === "24sevenoffice" && (
          <div className="credentials-input">
            <label htmlFor="username">Brukernavn:</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Skriv inn brukernavn"
            />
            <label htmlFor="password">Passord:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Skriv inn passord"
            />
          </div>
        )}

        {options.find((opt) => opt.id === selectedSource)?.requiresApiKey && (
          <div className="api-key-input">
            <label htmlFor="api-key">
              Legg inn API-brukernøkkel for autentisering:
            </label>
            <input
              type="text"
              id="api-key"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              placeholder="Skriv inn API-nøkkel"
            />
          </div>
        )}

        <p className="info-text">
          Se instuksjoner under hjelp for hvordan opprette API nøkler.
        </p>

        <div className="buttons">
          <button className="save-button" onClick={handleSave}>
            Lagre endringer
          </button>
          <button className="cancel-button">Avbryt</button>
        </div>
        <button
          className="import-log-button"
          onClick={() => setShowImportLog(!showImportLog)}
        >
          Vis importlogg
        </button>

        {showImportLog && (
          <div className="import-log">
            <h3>Importlogg</h3>
            <p>Her kan du se siste importhendelser.</p>
            {/* Add more detailed log information here */}
          </div>
        )}
      </div>
    </div>
  );
};

export default DefineImport;
