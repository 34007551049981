import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './CustomerList.css';

const CustomerList = () => {
    const [customerList, setCustomerList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm); // State for debounced search term
    const selectedYear = localStorage.getItem('selectedYear') || '';
    const selectedCustomer = localStorage.getItem('selectedCustomer') || '';

    // State to track edits
    const [editedCustomers, setEditedCustomers] = useState({});

    // Debounce effect
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300); // Adjust the debounce delay as necessary (e.g., 300ms)

        return () => {
            clearTimeout(handler); // Cleanup the timeout on unmount or when searchTerm changes
        };
    }, [searchTerm]);

    useEffect(() => {
        const fetchCustomerList = async () => {
            if (!selectedYear || !selectedCustomer) return;

            setLoading(true);
            try {
                const response = await fetch(`https://app.ecofocus.no:3443/api/suppliers?year=${selectedYear}&customer=${selectedCustomer}&limit=11&page=${currentPage}&search=${encodeURIComponent(debouncedSearchTerm)}`);
                const data = await response.json();
                if (Array.isArray(data.customerlist)) {
                    setCustomerList(data.customerlist);
                    setTotalPages(Math.ceil(data.total / 11));
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchCustomerList();
    }, [selectedYear, selectedCustomer, currentPage, debouncedSearchTerm]); // Trigger fetch when debounced search term changes

    // Handle input change for editable fields
    const handleInputChange = (e, id, field) => {
        const value = e.target.value;
        setEditedCustomers(prev => ({
            ...prev,
            [id]: { ...prev[id], [field]: value }
        }));
    };

    const saveChanges = async (customerId) => {
        const updatedData = editedCustomers[customerId];
        try {
            const response = await fetch(`https://app.ecofocus.no:3443/api/suppliers/${customerId}?year=${selectedYear}&customer=${selectedCustomer}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(updatedData)
            });

            if (response.ok) {
                setCustomerList(prevList => prevList.map(customer =>
                    customer.id === customerId ? { ...customer, ...updatedData } : customer
                ));
            } else {
                console.error('Failed to save changes');
            }
        } catch (error) {
            console.error('Error saving changes:', error);
        }
    };

    return (
        <div className="customer-list-container">
            <h1>Leverandører</h1>
            <input
                type="text"
                placeholder="Søk etter navn eller org.nr..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ marginBottom: '20px', padding: '10px', width: '300px' }}
            />
            {loading ? (
                <p>Loading data...</p>
            ) : customerList.length > 0 ? (
                <>
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Type</th>
                                <th>Org.Nr.</th>
                                <th>Navn</th>
                                <th>LeverandørID</th>
                                <th>Leverandør Faktor</th>
                                <th>Faktor År</th>
                                <th>Handling</th>
                            </tr>
                        </thead>
                        <tbody>
                            {customerList.map((customer) => (
                                <tr key={customer.id}>
                                    <td>
                                        <Link to={`/supplier/${customer.id}`}>{customer.id}</Link> {/* Clickable ID */}
                                    </td>
                                    <td>{customer.Type}</td>
                                    <td>
                                        <Link to={`/supplier/${customer.id}`}>{customer.RegistrationNumber}</Link> {/* Clickable Org.Nr. */}
                                    </td>
                                    <td>
                                        <Link to={`/supplier/${customer.id}`}>{customer.Name}</Link> {/* Clickable Name */}
                                    </td>
                                    <td>
                                        <Link to={`/supplier/${customer.id}`}>{customer.CustomerSupplierID}</Link> {/* Clickable LeverandørID */}
                                    </td>
                                    {/* Editable fields for factor */}
                                    <td>
                                        <input
                                            type="text"
                                            value={editedCustomers[customer.id]?.CustomerFactor || customer.CustomerFactor || ''}
                                            onChange={(e) => handleInputChange(e, customer.id, 'CustomerFactor')}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={editedCustomers[customer.id]?.CustomerFactorYear || customer.CustomerFactorYear || ''}
                                            onChange={(e) => handleInputChange(e, customer.id, 'CustomerFactorYear')}
                                        />
                                    </td>
                                    <td>
                                        <button onClick={() => saveChanges(customer.id)}>Lagre</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {/* Pagination */}
                    <div className="pagination">
                        <button disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)}>Forrige</button>
                        <span>Side {currentPage} av {totalPages}</span>
                        <button disabled={currentPage === totalPages} onClick={() => setCurrentPage(currentPage + 1)}>Neste</button>
                    </div>
                </>
            ) : (
                <p>No data available.</p>
            )}
        </div>
    );
};

export default CustomerList;
