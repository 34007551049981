// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.administration-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f4f4f9;
}

.admin-boxes {
    display: flex;
    flex-wrap: wrap; /* Allow boxes to wrap in case of smaller screens */
    justify-content: space-between;
    gap: 20px; /* Space between boxes */
    width: 100%; /* Make sure boxes take full width */
    max-width: 1200px; /* Optional: Max width for the container */
}

.admin-box {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    flex: 1 1 200px; /* Flexible box with a minimum width */
    text-align: center;
    transition: transform 0.2s;
}

.admin-box:hover {
    transform: translateY(-2px);
}

.admin-box h2 {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 10px;
}

.admin-box p {
    margin-bottom: 15px;
    color: #555;
}

.open-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.open-btn:hover {
    background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Administration.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,eAAe,EAAE,mDAAmD;IACpE,8BAA8B;IAC9B,SAAS,EAAE,wBAAwB;IACnC,WAAW,EAAE,oCAAoC;IACjD,iBAAiB,EAAE,0CAA0C;AACjE;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,wCAAwC;IACxC,aAAa;IACb,eAAe,EAAE,sCAAsC;IACvD,kBAAkB;IAClB,0BAA0B;AAC9B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".administration-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px;\n    background-color: #f4f4f9;\n}\n\n.admin-boxes {\n    display: flex;\n    flex-wrap: wrap; /* Allow boxes to wrap in case of smaller screens */\n    justify-content: space-between;\n    gap: 20px; /* Space between boxes */\n    width: 100%; /* Make sure boxes take full width */\n    max-width: 1200px; /* Optional: Max width for the container */\n}\n\n.admin-box {\n    background-color: #ffffff;\n    border-radius: 8px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    padding: 20px;\n    flex: 1 1 200px; /* Flexible box with a minimum width */\n    text-align: center;\n    transition: transform 0.2s;\n}\n\n.admin-box:hover {\n    transform: translateY(-2px);\n}\n\n.admin-box h2 {\n    font-size: 1.5em;\n    color: #333;\n    margin-bottom: 10px;\n}\n\n.admin-box p {\n    margin-bottom: 15px;\n    color: #555;\n}\n\n.open-btn {\n    background-color: #007bff;\n    color: white;\n    padding: 10px 15px;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.2s;\n}\n\n.open-btn:hover {\n    background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
